export default class Cookieflow {

    constructor (options) {
        const defaultOptions = {
            intro_headline: 'Cookie-Einstellungen',
            modal_max_width: 640,
            cookie_settings_href: '#cookieSettings',
            intro_accept_all_button_text: 'Alle akzeptieren',
            intro_customize_button_text: 'Individuell einstellen',
            intro_skip_button_text: 'Überspringen',
            save_button_text: 'Einstellungen speichern',
            footer_links: [],
            expires: 365,
        }

        // Merge the default options with given options.
        this.options = Object.assign({}, defaultOptions, options)

        // A simple cache to store the initialized cookies.
        this.initializedCookies = []

        document.querySelectorAll(`a[href="${this.options.cookie_settings_href}"]`).forEach(toggler => {
            toggler.addEventListener('click', (e) => {
                e.preventDefault()
                this.showSettingsModal()
            });
        })

        document.cookie.includes('cookieflow')
            ? this.dispatchCookieEvents()
            : this.showIntroModal()
    }

    createModal () {
        // Create the main modal
        const modal = document.createElement('div')
        modal.classList.add('modal')
        modal.style.maxWidth = `${this.options.modal_max_width}px`
        modal.appendChild(this.modalContent)

        // Create the wrapper element
        const wrapper = document.createElement('div')
        wrapper.classList.add('cookieflow')
        wrapper.appendChild(modal)

        return wrapper
    }

    showIntroModal () {
        // Modal content
        const content = document.createDocumentFragment()

        // Logo
        const image = document.createElement('img')
        image.src = this.options.intro_logo
        image.classList.add('logo')
        content.appendChild(image)

        // Into headline
        const headline = document.createElement('h2')
        headline.classList.add('intro-headline')
        headline.innerHTML = this.options.intro_headline
        content.appendChild(headline)

        // Intro text
        const text = document.createElement('p')
        text.classList.add('intro-text')
        text.innerHTML = this.options.intro_text
        content.appendChild(text)

        // Accept all button
        const acceptBtn = document.createElement('button')
        acceptBtn.classList.add(...this.options.accept_button_class?.split(' '))
        acceptBtn.innerHTML = this.options.intro_accept_all_button_text
        acceptBtn.addEventListener('click', () => {
            this.setAllCookies('accepted')
            this.dispatchCookieEvents()
            this.closeModal()
        })

        // Customize button
        const customizeBtn = document.createElement('button')
        customizeBtn.classList.add(...((this.options.customize_button_class || this.options.other_buttons_class)?.split(' ') || []))
        customizeBtn.innerHTML = this.options.intro_customize_button_text
        customizeBtn.addEventListener('click', () => {
            this.closeModal()
            this.showSettingsModal()
        })

        // Decline button
        const declineBtn = document.createElement('button')
        declineBtn.classList.add(...((this.options.decline_button_class || this.options.other_buttons_class)?.split(' ') || []))
        declineBtn.innerHTML = this.options.intro_skip_button_text
        declineBtn.addEventListener('click', () => {
            this.declineAllCookies()
            this.closeModal()
        })

        // Buttons wrapper
        const wrapper = document.createElement('div')
        wrapper.classList.add('buttons-wrapper')
        wrapper.appendChild(acceptBtn)
        wrapper.insertAdjacentHTML('beforeend', '<div class="flex-break"></div>')
        wrapper.appendChild(customizeBtn)
        wrapper.appendChild(declineBtn)
        content.appendChild(wrapper)

        // Footer links
        const links = document.createElement('div')
        links.classList.add('footer-links')
        this.options.footer_links.forEach((link) => {
            const a = document.createElement('a')
            a.classList.add('footer-link')
            a.href = link.url
            a.innerHTML = link.label

            if (link.target) {
                a.target = link.target
            }

            links.appendChild(a)
        })
        content.appendChild(links)

        this.modalContent = content

        this.showModal()
    }

    showSettingsModal () {
        // Set max width of the custom modal
        this.options.modal_max_width = this.options.customize_modal_max_width || this.options.modal_max_width

        // Modal content
        const content = document.createDocumentFragment()

        // Logo
        const image = document.createElement('img')
        image.src = this.options.intro_logo
        image.classList.add('logo')
        content.appendChild(image)

        // Customize modal headline
        const headline = document.createElement('h2')
        headline.classList.add('intro-headline')
        headline.innerHTML = (this.options.customize_headline || this.options.intro_headline)
        content.appendChild(headline)

        // Customize modal text
        if (this.options.customize_text) {
            const text = document.createElement('p')
            text.innerHTML = this.options.customize_text
            content.appendChild(text)
        }

        // Accept all button
        const acceptAllButton = document.createElement('button')

        if (this.allCookiesAccepted()) {
            acceptAllButton.classList.add('hidden')
        }

        acceptAllButton.classList.add('customize-accept-all-button', ...(this.options.customize_accept_button_class?.split(' ') || []))
        acceptAllButton.insertAdjacentHTML('beforeend', `<svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`)
        acceptAllButton.appendChild(document.createTextNode(this.options.intro_accept_all_button_text))
        acceptAllButton.addEventListener('click', () => {
            acceptAllButton.classList.add('hidden')
            groups.querySelectorAll('.has-cookies').forEach(checkbox => {
                checkbox.checked = true
                checkbox.dispatchEvent(new Event('change'))
                checkbox.dispatchEvent(new Event('toggle'))
            })
        })
        content.appendChild(acceptAllButton)

        // The accepted cookies
        const accepted = {}
        let cookiesCount = 0

        // Groups
        const groups = document.createElement('div')
        groups.classList.add('groups')
        this.options.groups.forEach((group, index) => {
            // Hidden Checkbox
            const checkbox = document.createElement('input')
            checkbox.type = 'checkbox'
            checkbox.disabled = group.required

            // Add an event listner to change children state.
            checkbox.addEventListener('change', e => {
                checkbox.dispatchEvent(new Event('toggle'))

                // content = The Group content
                content.querySelectorAll('input[type="checkbox"]').forEach(child => {
                    child.checked = e.target.checked
                    child.disabled = e.target.disabled
                    child.dispatchEvent(new Event('toggle'))
                    e.target.checked ? accepted[child.name] = 'accepted' : delete accepted[child.name]
                })

                // Toggle the accept all button.
                acceptAllButton.classList[cookiesCount === Object.keys(accepted).length ? 'add' : 'remove']('hidden')
            })

            // Group title
            const summary = document.createElement('summary')
            summary.classList.add('group-title')
            summary.innerHTML = group.title

            // Group content
            const content = document.createElement('div')
            content.classList.add('group-content')
            content.innerHTML = group.content || null

            const cookies = group.cookies
            const groupCookiesCount = cookies ? Object.keys(cookies).length : 0;

            // Group cookies
            if (group.cookies) {
                const isGroupRequired = group.required

                if (! isGroupRequired) {
                    // Add a class to the parent checkbox to track it later.
                    checkbox.classList.add('has-cookies')
                }

                cookiesCount += Object.keys(cookies).length

                Object.keys(cookies).forEach(key => {
                    const cookie = cookies[key]

                    // Hidden Checkbox
                    const child = document.createElement('input')
                    child.type = 'checkbox'
                    child.name = key
                    child.checked = cookie.required || isGroupRequired || this.accepted(key)
                    child.disabled = cookie.required || isGroupRequired || checkbox.disabled
                    child.checked ? accepted[key] = 'accepted' : delete accepted[key]

                    // Description
                    const cookieGroup = document.createElement('div')
                    cookieGroup.classList.add('group-cookie')
                    cookieGroup.insertAdjacentHTML('beforeend', `<div>${cookie.label}<p class="group-cookie-description">${cookie.description}</p></div>`)
                    cookieGroup.appendChild(child)

                    // Toggle
                    cookieGroup.appendChild(this.createToggleElement(child))

                    // Tooltip
                    if (group.tooltip) {
                        cookieGroup.appendChild(this.createTooltipElement(cookie.tooltip || group.tooltip, 'right'))
                    }

                    // Add an event listener to change the parent checkbox state.
                    child.addEventListener('change', e => {
                        checkbox.checked = content.querySelectorAll('input:checked')?.length === groupCookiesCount
                        checkbox.dispatchEvent(new Event('toggle'))
                        child.dispatchEvent(new Event('toggle'))
                        e.target.checked ? accepted[key] = 'accepted' : delete accepted[key]

                        acceptAllButton.classList[cookiesCount === Object.keys(accepted).length ? 'add' : 'remove']('hidden')
                    })

                    content.appendChild(cookieGroup)
                })
            }

            // Check the parent checkbox if one or more child is checked
            checkbox.checked = group.required || content.querySelectorAll('input:checked')?.length === groupCookiesCount
            checkbox.dispatchEvent(new Event('toggle'))

            const details = document.createElement('details')
            details.appendChild(summary)
            details.appendChild(content)

            const groupWrapper = document.createElement('div')
            groupWrapper.classList.add('group')
            groupWrapper.appendChild(checkbox)

            // Toggle
            groupWrapper.appendChild(this.createToggleElement(checkbox))

            // Tooltip
            if (group.tooltip) {
                groupWrapper.appendChild(this.createTooltipElement(group.tooltip, 'left'))
            }

            groupWrapper.appendChild(details)


            groups.appendChild(groupWrapper)

            if (index < (this.options.groups.length - 1)) {
                const hr = document.createElement('hr')
                hr.classList.add('divider')
                groups.appendChild(hr)
            }
        })
        content.appendChild(groups)

        // Save button
        const saveBtn = document.createElement('button')
        saveBtn.classList.add(...(this.options.customize_modal_button_class?.split(' ') || []))
        saveBtn.innerHTML = this.options.save_button_text
        saveBtn.addEventListener('click', () => {
            this.declineAllCookies()
            this.setCookies(Object.keys(accepted), 'accepted')
            this.dispatchCookieEvents()
            this.closeModal()
        })

        // Buttons wrapper
        const wrapper = document.createElement('div')
        wrapper.classList.add('buttons-wrapper')
        wrapper.appendChild(saveBtn)
        content.appendChild(wrapper)

        this.modalContent = content

        this.showModal()
    }

    createToggleElement (checkbox) {
        // handle
        const handle = document.createElement('div')
        handle.classList.add('handle')

        // Toggle
        const toggle = document.createElement('div')
        toggle.classList.add('toggle')

        if (checkbox.disabled) {
            toggle.classList.add('disabled')
        }

        if (checkbox.checked) {
            toggle.classList.add('active')
            handle.classList.add('active')
        }

        checkbox.addEventListener('toggle', e => {
            toggle.classList[e.target.disabled ? 'add' : 'remove']('disabled')
            toggle.classList[e.target.checked ? 'add' : 'remove']('active')
            handle.classList[e.target.checked ? 'add' : 'remove']('active')
        })

        toggle.addEventListener('click', () => {
            if (! checkbox.disabled) {
                toggle.classList[checkbox.checked ? 'add' : 'remove']('active')
                handle.classList[checkbox.checked ? 'add' : 'remove']('active')
                checkbox.checked = ! checkbox.checked
                checkbox.dispatchEvent(new Event('change'))
            }
        })

        toggle.appendChild(handle)

        return toggle
    }

    createTooltipElement (text, classes) {
        const tooltip = document.createElement('div')
        tooltip.classList.add('tooltip', ...(classes?.split(' ') || []))
        tooltip.innerHTML = text

        return tooltip
    }

    setAllCookies (value) {
        this.options.groups.forEach(group => {
            if (group.cookies) {
                this.setCookies(Object.keys(group.cookies), value)
            }
        })
    }

    setCookies (cookies, value) {
        cookies.forEach(cookie => {
            const date = new Date()
            date.setTime(date.getTime() + this.options.expires * 60 * 60 * 24 * 1000)
            document.cookie = `cookieflow_${cookie}=${value};expires=${date.toUTCString()};path=/`
        })
    }

    declineAllCookies (refresh = false) {
        this.setAllCookies('declined')

        if (refresh) {
            location.reload()
        }
    }

    allCookiesAccepted () {
        let accepted = true

        this.options.groups.forEach(group => {
            if (group.cookies) {
                Object.keys(group.cookies).forEach(key => {
                    if (! this.accepted(key)) {
                        accepted = false
                    }
                })
            }
        })

        return accepted
    }

    dispatchCookieEvents () {
        this.options.groups.forEach(group => {
            if (group.cookies) {
                Object.keys(group.cookies).forEach(key => {
                    if (this.accepted(key) && ! this.initializedCookies.includes(key)) {
                        group.cookies[key].onAccept?.()
                        this.initializedCookies.push(key)
                    }
                })
            }
        })
    }

    accepted (key) {
        return document.cookie.includes(`cookieflow_${key}=accepted`)
    }

    showModal () {
        document.body.appendChild(this.modal = this.createModal())
    }

    closeModal () {
        document.body.removeChild(this.modal)
    }
}

window.Cookieflow = Cookieflow